import {useEffect} from 'react';

import LocomotiveScroll from 'locomotive-scroll';

import 'locomotive-scroll/dist/locomotive-scroll.css';

interface Props {
  dependencies?: React.DependencyList;
}

export default function CustomLocomotiveScroll(props: Props) {
  const {dependencies} = props;

  useEffect(() => {
    const element = document.querySelector('[data-scroll-container]');

    if (!element) {
      return;
    }

    const locomotiveScroll = new LocomotiveScroll({
      el: element,
      smooth: true,
      smoothMobile: false,
      scrollFromAnywhere: true,
      scrollbarClass: 'scroll-bar',
    });

    locomotiveScroll.update();

    return () => {
      if (locomotiveScroll) {
        locomotiveScroll.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return null;
}
