import classNames from 'classnames';

import {TextTagName} from 'types';
import {variationName} from 'utilities';

import * as styles from './DisplayText.module.scss';

type Size = 'small' | 'medium' | 'large' | 'extraLarge';

interface Props {
  element?: TextTagName;
  size?: Size;
  children: React.ReactNode;
}

export default function DisplayText(props: Props) {
  const {element: Element = 'p', size = 'medium', children} = props;
  const className = classNames(
    styles.DisplayText,
    size && styles[variationName('size', size)]
  );

  return <Element className={className}>{children}</Element>;
}
