import {Link} from 'gatsby';

import {Stack, Text} from 'components';

import * as styles from './CloseButton.module.scss';

interface Props {
  url: string;
}

export default function CloseButton(props: Props) {
  const {url} = props;

  return (
    <Link to={url}>
      <Stack spacing="tight" align="center" horizontal>
        <div className={styles.CloseLabel}>
          <Text>Close</Text>
        </div>

        <div className={styles.CloseButtonCircle}>
          <span className={styles.CloseButtonIcon}>✗</span>
        </div>
      </Stack>
    </Link>
  );
}
