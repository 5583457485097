import {MenuButton} from '../..';

import * as styles from './Base.module.scss';

interface Props {
  menuOpen: boolean;
  toggleMenu: () => void;
}

export default function SideNav(props: Props) {
  const {menuOpen, toggleMenu} = props;

  return (
    <div className={styles.MenuButtonContainer}>
      <MenuButton open={menuOpen} onClick={toggleMenu} />
    </div>
  );
}
