import classNames from 'classnames';

import {variationName} from 'utilities';

import * as styles from './Bubble.module.scss';

type Style = 'orange' | 'green' | 'red' | 'blue';

interface Props {
  style: Style;
}

export default function Bubble(props: Props) {
  const {style, ...rest} = props;

  const className = classNames(
    styles.Bubble,
    styles[variationName('style', style)]
  );

  return <div className={className} {...rest} />;
}
