// extracted by mini-css-extract-plugin
export var AspectRatioWrapper = "r_bz";
export var Caption = "r_Y";
export var Image = "r_bn";
export var ImageOverlay = "r_bB";
export var Link = "r_bg";
export var NoSubtitle = "r_bx";
export var PortfolioItem = "r_bs";
export var ReverseOrientation = "r_bt";
export var RightColumn = "r_by";
export var Title = "r_bw";
export var TopLayer = "r_bv";