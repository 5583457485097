import classNames from 'classnames';

import * as styles from './ImageActionOverlay.module.scss';

type Style = 'play' | 'openExternal';

interface Props {
  style?: Style;
  onClick?: () => void;
}

export default function ImageActionOverlay(props: Props) {
  const {style, onClick} = props;

  const icon = iconForStyle(style);
  const className = classNames(
    styles.ImageActionOverlay,
    onClick && styles.Clickable,
    style === 'play' && styles.PlayIcon
  );

  return (
    <div
      className={className}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      aria-pressed={false}
    >
      {icon && (
        <div className={styles.OpenButton}>
          <span className={styles.OpenButtonIcon}>{icon}</span>
        </div>
      )}
    </div>
  );
}

function iconForStyle(style?: Style) {
  switch (style) {
    case 'play':
      return '▶';
    case 'openExternal':
      return '↗';
    default:
      return null;
  }
}
