import classNames from 'classnames';

import * as styles from './MenuButton.module.scss';

interface Props {
  open: boolean;
  onClick: () => void;
}

export default function MenuButton(props: Props) {
  const {open, onClick} = props;

  const className = classNames(styles.MenuButton, open && styles.Open);
  const label = open ? 'Close menu' : 'Open menu';

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.keyCode !== 32 && event.keyCode !== 13) {
      return;
    }

    onClick();
  }

  return (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
      role="button"
      aria-pressed={false}
      aria-expanded={open}
      aria-label={label}
    >
      <div className={styles.Bar1}></div>
      <div className={styles.Bar2}></div>
    </div>
  );
}
