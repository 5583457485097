import {DisplayText, Frame, Layout, Stack} from 'components';

import * as styles from './Page.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function Page(props: Props) {
  const {title, children} = props;

  return (
    <Frame.Local title={title}>
      <Layout.Base>
        <div className={styles.Page}>
          <Stack spacing="loose">
            <DisplayText size="large">{title}</DisplayText>
            <div>{children}</div>
          </Stack>
        </div>
      </Layout.Base>
    </Frame.Local>
  );
}
