import * as styles from './Base.module.scss';
import {VerticalLines} from './components';

interface Props {
  linesHidden?: boolean;
  children: React.ReactNode;
}

export default function Base(props: Props) {
  const {linesHidden = false, children} = props;

  return (
    <div className={styles.Base}>
      {!linesHidden && <VerticalLines />}

      <div data-scroll-container>
        <main>{children}</main>
      </div>
    </div>
  );
}
