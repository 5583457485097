import {Stack} from 'components';

import {MenuButton} from '..';

import {Branding, CloseButton} from './components';
import * as styles from './TopNav.module.scss';

interface Props {
  menuOpen: boolean;
  toggleMenu: () => void;
  showTitle?: boolean;
  closeButtonDestination?: string;
}

export default function TopNav(props: Props) {
  const {
    menuOpen,
    toggleMenu,
    showTitle = false,
    closeButtonDestination,
  } = props;

  return (
    <Stack justify="spaceBetween" align="center" horizontal>
      <Branding showTitle={showTitle} />

      {!closeButtonDestination && (
        <div className={styles.MenuButtonContainer}>
          <MenuButton open={menuOpen} onClick={toggleMenu} />
        </div>
      )}

      {closeButtonDestination && <CloseButton url={closeButtonDestination} />}
    </Stack>
  );
}
