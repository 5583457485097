import {Stack, Text, TextLink} from 'components';

export default function ContactInformation() {
  return (
    <Stack spacing="extraTight">
      <Text type="subheading">Get in touch</Text>
      <Text>
        Letʼs work on your next great idea together! If you have a business or
        volunteer enquiry, please send me an email.
      </Text>

      <Text>
        <TextLink to="mailto:hello@romsicki.com" external>
          hello@romsicki.com
        </TextLink>
      </Text>
    </Stack>
  );
}
