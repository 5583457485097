import {AppContext} from '../AppContext';
import {SideNav, SideNavContainer} from '../components';

interface Props {
  title?: string;
  children?: React.ReactNode;
}

export default function Local(props: Props) {
  const {title, children} = props;

  return (
    <AppContext.Consumer>
      {({data: {menuOpen, usesModalPresentation}}) => (
        <>
          {!usesModalPresentation && (
            <SideNavContainer>
              <SideNav.Metadata
                visible={!menuOpen}
                pageTitle={title || 'Home'}
              />
            </SideNavContainer>
          )}

          {children}
        </>
      )}
    </AppContext.Consumer>
  );
}
