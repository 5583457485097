// extracted by mini-css-extract-plugin
export var Horizontal = "f_v";
export var Stack = "f_t";
export var alignCenter = "f_N";
export var alignEnd = "f_Q";
export var alignStart = "f_P";
export var alignStretch = "f_R";
export var justifyCenter = "f_J";
export var justifyEnd = "f_G";
export var justifySpaceAround = "f_L";
export var justifySpaceBetween = "f_M";
export var justifySpaceEvenly = "f_K";
export var justifyStart = "f_F";
export var justifyStretch = "f_H";
export var spacingExtraLoose = "f_C";
export var spacingExtraTight = "f_x";
export var spacingGenerous = "f_D";
export var spacingLoose = "f_B";
export var spacingMinimal = "f_w";
export var spacingNormal = "f_z";
export var spacingTight = "f_y";