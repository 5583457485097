import * as styles from './SideNavContainer.module.scss';

interface Props {
  children: React.ReactNode;
}

export default function SideNavContainer(props: Props) {
  const {children} = props;

  return <div className={styles.SideNavContainer}>{children}</div>;
}
