import {CSSTransition} from 'react-transition-group';

import {Stack} from 'components';

import {ContactInformation, MenuItem, SocialIcons} from './components';
import * as styles from './Menu.module.scss';

interface Props {
  open: boolean;
}

export default function Menu(props: Props) {
  const {open} = props;

  return (
    <CSSTransition
      in={open}
      timeout={1000}
      classNames={{
        enterActive: styles.EnterActive,
        enterDone: styles.EnterDone,
        exit: styles.Exit,
        exitActive: styles.ExitActive,
        exitDone: styles.ExitDone,
      }}
      unmountOnExit
    >
      <div>
        <div className={styles.MenuBackground} />
        <div className={styles.Menu}>
          <div className={styles.MenuContent}>
            <div className={styles.MenuItems}>
              <Stack align="end" spacing="loose">
                <div data-menu-transitionable>
                  <MenuItem destination="/">Home</MenuItem>
                </div>
                <div data-menu-transitionable>
                  <MenuItem destination="/portfolio">Portfolio</MenuItem>
                </div>
              </Stack>
            </div>

            <div className={styles.ContactSocial} data-menu-transitionable>
              <Stack spacing="loose">
                <ContactInformation />
                <SocialIcons />
              </Stack>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
