import {Link} from 'gatsby';

import * as styles from './TextLink.module.scss';

interface Props {
  external?: boolean;
  to: string;
  rel?: string;
  children: React.ReactNode;
}

export default function TextLink(props: Props) {
  const {external = false, to, rel, children} = props;

  if (external) {
    return (
      // I know what I'm doing!
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        className={styles.Link}
        href={to}
        target={external ? '_blank' : undefined}
        rel={rel ? `${rel} noreferrer` : 'noreferrer'}
      >
        {children}
      </a>
    );
  }

  return (
    <Link className={styles.Link} to={to}>
      {children}
    </Link>
  );
}
