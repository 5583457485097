// extracted by mini-css-extract-plugin
export var ContactSocial = "k_bf";
export var EnterActive = "k_5";
export var EnterDone = "k_6";
export var Exit = "k_7";
export var ExitActive = "k_8";
export var ExitDone = "k_9";
export var Menu = "k_bb";
export var MenuBackground = "k_4";
export var MenuContent = "k_bc";
export var MenuItems = "k_bd";