import {Link} from 'gatsby';

import {DisplayText} from 'components';

import * as styles from './MenuItem.module.scss';

interface Props {
  destination: string;
  children: string;
}

export default function MenuItem(props: Props) {
  const {destination, children} = props;

  return (
    <Link
      to={destination}
      className={styles.MenuItem}
      activeClassName={styles.ActiveMenuItem}
    >
      <DisplayText size="medium" element="h1">
        {children}
      </DisplayText>
    </Link>
  );
}
