import * as styles from './Caption.module.scss';

interface Props {
  children: React.ReactNode;
}

export default function Caption(props: Props) {
  const {children} = props;

  return <p className={styles.Caption}>{children}</p>;
}
