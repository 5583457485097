import {Base} from './Base';
import {Column} from './Column';
import {Row} from './Row';

const Layout = {
  Base,
  Column,
  Row,
};

export {Layout};
