import classNames from 'classnames';
import {Link} from 'gatsby';

import {DisplayText, ImageActionOverlay, Stack, Text} from 'components';

import * as styles from './PortfolioItem.module.scss';

type Orientation = 'left' | 'right';

interface Props {
  linkTo?: string | null;
  title: string;
  subtitle?: string | null;
  year: number;
  category: string;
  image: React.ReactNode;
  orientation?: Orientation;
}

export default function PortfolioItem(props: Props) {
  const {
    linkTo,
    title,
    subtitle,
    year,
    category,
    image,
    orientation = 'left',
  } = props;

  const className = classNames(
    styles.PortfolioItem,
    orientation === 'right' && styles.ReverseOrientation
  );

  const titleClassName = classNames(
    styles.Title,
    subtitle || styles.NoSubtitle
  );

  const content = (
    <div className={className}>
      <span className={styles.Caption}>
        {year} — {category}
      </span>

      <div className={styles.RightColumn}>
        <div className={styles.AspectRatioWrapper}>
          <div className={styles.Image}>{image}</div>

          <div className={styles.ImageOverlay}>
            <ImageActionOverlay style="openExternal" />
          </div>

          <div className={styles.TopLayer}>
            <Stack
              spacing="minimal"
              align={orientation === 'right' ? 'end' : 'start'}
            >
              {subtitle && <Text element="h3">{subtitle}</Text>}

              <div className={titleClassName}>
                <DisplayText size="medium" element="h2">
                  {title}
                </DisplayText>
              </div>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );

  if (linkTo) {
    return (
      <Link to={linkTo} className={styles.Link}>
        {content}
      </Link>
    );
  }

  return content;
}
