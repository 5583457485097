import {Link} from 'gatsby';

import {Caption, DisplayText, Layout, Stack} from 'components';

import * as styles from './Footer.module.scss';

interface Props {
  back?: boolean;
  title: string;
  url: string;
}

export default function Footer(props: Props) {
  const {back = false, title, url} = props;

  const actionText = back ? 'Back to' : 'Next';

  return (
    <footer className={styles.Footer}>
      <div className={styles.GradientDivider} />

      <div className={styles.Content}>
        <Link to={url}>
          <Layout.Row>
            <Layout.Column>
              <div className={styles.Arrow}>
                <DisplayText size="medium">→</DisplayText>
              </div>
            </Layout.Column>

            <Layout.Column>
              <Stack spacing="extraTight">
                <Caption>{actionText}</Caption>
                <DisplayText size="medium">{title}</DisplayText>
              </Stack>
            </Layout.Column>
          </Layout.Row>
        </Link>
      </div>
    </footer>
  );
}
