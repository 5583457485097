import {AppContext} from './AppContext';
import {Local} from './Local';
import {Root} from './Root';

const Frame = {
  Root,
  Local,
};

export {Frame, AppContext};
