import {createContext} from 'react';

interface Context {
  data: {
    menuOpen: boolean;
    usesModalPresentation: boolean;
    transitioning: boolean;
  };
}

const defaultContextValue: Context = {
  data: {
    menuOpen: false,
    usesModalPresentation: false,
    transitioning: false,
  },
};

export const AppContext = createContext<Context>(defaultContextValue);
