import {useLocation} from '@reach/router';
import {graphql, useStaticQuery} from 'gatsby';

interface Props {
  title?: string;
  description?: string | null;
  image?: string | null;
}

export default function SEO(props: Props) {
  const {pathname} = useLocation();
  const {site} = useStaticQuery<Queries.SEOQuery>(query);

  const {title, description, image} = props;

  if (!site || !site.siteMetadata) {
    return null;
  }

  const {
    siteMetadata: {defaultTitle, defaultDescription, siteUrl, twitterUsername},
  } = site;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image ? `${siteUrl}${image}` : undefined,
    siteUrl: `${siteUrl}${pathname}`,
  };

  return (
    <>
      <title>{seo.title}</title>
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.siteUrl && <meta name="og:url" content={seo.siteUrl} />}
      {seo.title && <meta name="og:title" content={seo.title} />}
      {seo.description && (
        <meta name="og:description" content={seo.description} />
      )}
      {seo.image && <meta name="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </>
  );
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        twitterUsername
      }
    }
  }
`;
