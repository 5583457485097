import classNames from 'classnames';
import {Link, graphql, useStaticQuery} from 'gatsby';

import {Stack, Text} from 'components';

import * as styles from './Branding.module.scss';
import logo from './images/logo.svg';

interface Props {
  showTitle?: boolean;
}

export default function Branding(props: Props) {
  const {showTitle = false} = props;
  const {site} = useStaticQuery<Queries.BrandingQuery>(query);

  if (!site || !site.siteMetadata) {
    return null;
  }

  const {title} = site.siteMetadata;

  const titleClassName = classNames(
    styles.Title,
    showTitle && styles.TitleVisible
  );

  return (
    <div className={styles.Branding}>
      <Link to="/">
        <Stack spacing="tight" horizontal align="center">
          <img src={logo} alt="Logo" />

          <div className={titleClassName}>
            <Text type="subheading">{title}</Text>
          </div>
        </Stack>
      </Link>
    </div>
  );
}

const query = graphql`
  query Branding {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
