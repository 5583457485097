import classNames from 'classnames';

import * as styles from './Row.module.scss';

interface Props {
  reverse?: boolean;
  children: React.ReactNode;
}

export default function Row(props: Props) {
  const {reverse = false, children} = props;

  const className = classNames(styles.Row, reverse && styles.Reverse);

  return <div className={className}>{children}</div>;
}
