import React, {useState} from 'react';

import {ImageActionOverlay} from 'components';

import * as styles from './ToggleableEmbed.module.scss';

interface Props {
  image: React.ReactNode;
  href?: string | null;
}

export default function ToggleableEmbed(props: Props) {
  const {image, href} = props;
  const [enabled, setEnabled] = useState(false);

  function enableEmbed() {
    setEnabled(true);
  }

  return (
    <div className={styles.ToggleableEmbed} data-hide-cursor>
      {!enabled && <div className={styles.Image}>{image}</div>}

      <div className={styles.ContentWrapper}>
        {enabled && (
          <iframe
            src={href ?? undefined}
            frameBorder={0}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </div>

      {!enabled && href && (
        <ImageActionOverlay style="play" onClick={enableEmbed} />
      )}
    </div>
  );
}
