import classNames from 'classnames';

import {variationName} from 'utilities';

import * as styles from './Stack.module.scss';

type Spacing =
  | 'minimal'
  | 'extraTight'
  | 'tight'
  | 'normal'
  | 'loose'
  | 'extraLoose'
  | 'generous';

type Justification =
  | 'start'
  | 'end'
  | 'center'
  | 'stretch'
  | 'spaceEvenly'
  | 'spaceAround'
  | 'spaceBetween';

type Alignment = 'start' | 'end' | 'center' | 'stretch';

interface Props {
  spacing?: Spacing;
  justify?: Justification;
  align?: Alignment;
  horizontal?: boolean;
  children: React.ReactNode;
}

export default function Stack(props: Props) {
  const {
    spacing = 'normal',
    justify,
    align,
    horizontal = false,
    children,
  } = props;

  const className = classNames(
    styles.Stack,
    styles[variationName('spacing', spacing)],
    justify && styles[variationName('justify', justify)],
    align && styles[variationName('align', align)],
    horizontal && styles.Horizontal
  );

  return <div className={className}>{children}</div>;
}
