import {useEffect, useState} from 'react';

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      ) ||
        (window.navigator.platform === 'MacIntel' &&
          window.navigator.maxTouchPoints > 1)
    );

    setMobile(mobile);

    const reducedMotion = matchMedia(
      '(prefers-reduced-motion: reduce)'
    ).matches;
    setPrefersReducedMotion(reducedMotion);
  }, []);

  return {isMobile, prefersReducedMotion};
}
