import {Frame} from 'components';

interface SitePage extends Queries.SitePage {
  usesModalLayout?: boolean;
}

interface Props {
  children: React.ReactNode;
  location: Location;
  pageContext: SitePage;
}

export default function LayoutIndex(props: Props) {
  const {children, location, pageContext} = props;

  const modalCloseDestination = pageContext.usesModalLayout ? '..' : undefined;

  return (
    <Frame.Root
      location={location}
      modalCloseDestination={modalCloseDestination}
    >
      {children}
    </Frame.Root>
  );
}
