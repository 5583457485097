import {useEffect, useMemo, useState} from 'react';

import classNames from 'classnames';

import {LocomotiveScroll, Menu} from 'components';
import {useDeviceDetect} from 'utilities';

import {AppContext} from '../AppContext';
import {
  Cursor,
  SideNav,
  SideNavContainer,
  TopNav,
  Transition,
} from '../components';

import * as styles from './Root.module.scss';

interface Props {
  location: Location;
  modalCloseDestination?: string;
  children: React.ReactNode;
}

export default function Root(props: Props) {
  const {location, modalCloseDestination, children} = props;

  const [menuOpen, setMenuOpen] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const [usesModalPresentation, setUsesModalPresentation] = useState(
    !!modalCloseDestination
  );
  const {isMobile, prefersReducedMotion} = useDeviceDetect();

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  function handleTransitionEnd() {
    setTransitioning(false);
    setUsesModalPresentation(!!modalCloseDestination);
  }

  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add(styles.NoScroll);
    } else {
      document.body.classList.remove(styles.NoScroll);
    }
  }, [menuOpen]);

  const currentContext = useMemo(
    () => ({
      data: {
        menuOpen,
        usesModalPresentation,
        transitioning,
      },
    }),
    [menuOpen, transitioning, usesModalPresentation]
  );

  const topNavContainerClassName = classNames(
    styles.TopNavContainer,
    modalCloseDestination && styles.TopNavContainerModalPresentation
  );

  return (
    <AppContext.Provider value={currentContext}>
      <LocomotiveScroll dependencies={[location.pathname]} />

      {!isMobile && !prefersReducedMotion && <Cursor />}

      <Transition
        location={location}
        duration={500}
        onTransitionStart={() => setTransitioning(true)}
        onTransitionEnd={handleTransitionEnd}
      >
        {children}

        <Menu open={menuOpen} />

        <div className={topNavContainerClassName}>
          <TopNav
            menuOpen={menuOpen}
            toggleMenu={toggleMenu}
            showTitle={menuOpen}
            closeButtonDestination={modalCloseDestination}
          />
        </div>

        {!modalCloseDestination && (
          <SideNavContainer>
            <SideNav.Base menuOpen={menuOpen} toggleMenu={toggleMenu} />
          </SideNavContainer>
        )}
      </Transition>

      <a
        rel="me"
        href="https://mastodon.social/@lfroms"
        style={{display: 'none'}}
      >
        Mastodon
      </a>
    </AppContext.Provider>
  );
}
