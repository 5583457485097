import {useEffect, useRef, useState} from 'react';

import classNames from 'classnames';

import * as styles from './Cursor.module.scss';

export default function Cursor() {
  const [hovering, setHovering] = useState(false);
  const [visible, setVisible] = useState(true);

  const cursorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let mouseX = -100;
    let mouseY = -100;
    let cursorX = mouseX;
    let cursorY = mouseY;

    document.body.classList.add(styles.NoCursor);

    document.addEventListener('mousemove', (event: MouseEvent) => {
      mouseX = event.clientX;
      mouseY = event.clientY;

      if (
        document.querySelector('a:hover, button:hover, [role="button"]:hover')
      ) {
        setHovering(true);
      } else {
        setHovering(false);
      }
    });

    document.addEventListener('DOMNodeInserted', () => {
      const addedIframe = document.querySelector('[data-hide-cursor] iframe');

      if (!addedIframe) {
        return;
      }

      addedIframe.addEventListener('mouseenter', () => {
        setVisible(false);
      });

      addedIframe.addEventListener('mouseleave', () => {
        setVisible(true);
      });
    });

    document.body.addEventListener('mouseleave', () => {
      setVisible(false);
    });

    document.body.addEventListener('mouseenter', () => {
      setVisible(true);
    });

    function render() {
      cursorX = momentum(cursorX, mouseX, 0.7);
      cursorY = momentum(cursorY, mouseY, 0.7);

      if (cursorRef.current) {
        cursorRef.current.style.transform = createTransform(cursorX, cursorY);
      }

      requestAnimationFrame(render);
    }

    requestAnimationFrame(render);
  }, []);

  const innerClassName = classNames(
    styles.Cursor,
    hovering && styles.Hovering,
    !visible && styles.Hidden
  );

  return (
    <>
      <div className={innerClassName} ref={cursorRef} />
    </>
  );
}

function createTransform(x: number, y: number) {
  return `translate(${x}px, ${y}px)`;
}

function momentum(
  currentPosition: number,
  desiredPosition: number,
  mass: number
) {
  return (1 - mass) * currentPosition + mass * desiredPosition;
}
