import classNames from 'classnames';

import {TextTagName} from 'types';
import {variationName} from 'utilities';

import * as styles from './Text.module.scss';

type Type = 'heading' | 'subheading' | 'body';

interface Props {
  element?: TextTagName;
  type?: Type;
  children: React.ReactNode;
}

export default function Text(props: Props) {
  const {
    type = 'body',
    element: Element = elementForType(type),
    children,
  } = props;
  const className = classNames(
    styles.Text,
    styles[variationName('type', type)]
  );

  return <Element className={className}>{children}</Element>;
}

function elementForType(type?: Type): TextTagName {
  switch (type) {
    case 'heading':
      return 'h2';
    case 'subheading':
      return 'h3';
    case 'body':
      return 'p';
    default:
      return 'p';
  }
}
