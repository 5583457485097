import classNames from 'classnames';

import {Caption} from 'components';

import * as styles from './Metadata.module.scss';

interface Props {
  pageTitle: string;
  visible?: boolean;
}

export default function Metadata(props: Props) {
  const {pageTitle, visible = true} = props;

  const className = classNames(
    styles.Metadata,
    !visible && styles.HidesWhenMenuOpen
  );

  return (
    <div className={className}>
      <div className={styles.RotateNinety}>
        <Caption>{pageTitle}</Caption>
      </div>

      <div className={styles.RotateNinety}>
        <Caption>Copyright &copy; 2024</Caption>
      </div>
    </div>
  );
}
