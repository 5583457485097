import {
  faFlickr,
  faGithub,
  faImdb,
  faLinkedin,
  faMastodon,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Stack, TextLink} from 'components';

import * as styles from './SocialIcons.module.scss';

export default function SocialIcons() {
  return (
    <div className={styles.SocialIcons}>
      <Stack spacing="tight" horizontal>
        <TextLink to="https://github.com/lfroms" external>
          <FontAwesomeIcon icon={faGithub} />
        </TextLink>
        <TextLink to="https://www.linkedin.com/in/lfroms" external>
          <FontAwesomeIcon icon={faLinkedin} />
        </TextLink>
        <TextLink to="https://mastodon.social/@lfroms" rel="me" external>
          <FontAwesomeIcon icon={faMastodon} />
        </TextLink>
        <TextLink to="https://www.flickr.com/photos/lfroms" external>
          <FontAwesomeIcon icon={faFlickr} />
        </TextLink>
        <TextLink to="https://www.youtube.com/lukasromsicki" external>
          <FontAwesomeIcon icon={faYoutube} />
        </TextLink>
        <TextLink to="https://www.imdb.com/name/nm9754768/" external>
          <FontAwesomeIcon icon={faImdb} />
        </TextLink>
      </Stack>
    </div>
  );
}
