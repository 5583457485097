import * as styles from './HorizontalLine.module.scss';

type BorderWeight = 'light' | 'heavy';

interface Props {
  weight?: BorderWeight | number;
}

export default function HorizontalLine(props: Props) {
  const {weight = 'light'} = props;

  const mappedWeight = mapWeightToNumber(weight);

  return (
    <div className={styles.HorizontalLine} style={{height: mappedWeight}} />
  );
}

function mapWeightToNumber(weight: BorderWeight | number) {
  if (typeof weight === 'number') {
    return weight;
  }

  if (weight === 'heavy') {
    return 5;
  }

  // light - default
  return 2;
}
