import * as styles from './VerticalLines.module.scss';

export default function VerticalLines() {
  return (
    <div className={styles.VerticalLines}>
      <div className={styles.Column} />
      <div className={styles.Column} />
    </div>
  );
}
