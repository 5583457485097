import {CSSTransition, TransitionGroup} from 'react-transition-group';

import * as styles from './Transition.module.scss';

interface Props {
  location: Location;
  duration: number;
  onTransitionStart?: () => void;
  onTransitionEnd?: () => void;
  children: React.ReactNode;
}

export default function Transition(props: Props) {
  const {location, duration, onTransitionStart, onTransitionEnd, children} =
    props;

  return (
    <TransitionGroup>
      <CSSTransition
        key={`${location.pathname}-1`}
        timeout={duration}
        unmountOnExit
        mountOnEnter
        classNames={{
          enterActive: styles.EnterActive,
          enterDone: styles.EnterDone,
          exitActive: styles.ExitActive,
          exitDone: styles.ExitDone,
        }}
      >
        <div className={styles.ContentWrapper}>{children}</div>
      </CSSTransition>

      <CSSTransition
        key={`${location.pathname}-2`}
        timeout={duration * 2}
        unmountOnExit
        mountOnEnter
        onEnter={onTransitionStart}
        onEntered={onTransitionEnd}
        classNames={{
          enterActive: styles.EnterActive,
          enterDone: styles.EnterDone,
        }}
      >
        <div
          className={styles.Curtain}
          style={{transitionDuration: `${duration}ms`}}
        />
      </CSSTransition>
    </TransitionGroup>
  );
}
